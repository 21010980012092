import { render, staticRenderFns } from "./WriteReview.vue?vue&type=template&id=d7fd86f2"
import script from "./WriteReview.vue?vue&type=script&lang=js"
export * from "./WriteReview.vue?vue&type=script&lang=js"
import style0 from "./WriteReview.vue?vue&type=style&index=0&id=d7fd86f2&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QCard from 'quasar/src/components/card/QCard.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QRating from 'quasar/src/components/rating/QRating.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QFile from 'quasar/src/components/file/QFile.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QColor from 'quasar/src/components/color/QColor.js';
import QField from 'quasar/src/components/field/QField.js';
import qInstall from "../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QCard,QBtn,QScrollArea,QImg,QRating,QIcon,QForm,QInput,QFile,QTooltip,QColor,QField});
