<template>
  <ftx-dialog
    :dialog="dialog"
    maximized
    position="bottom"
    :beforeShow="beforeShow"
    persistent
  >
    <template v-slot:DialogContent>
      <q-card class="writeReview">
        <div class="dialogHeader">
          <q-btn class="close" flat dense @click="closeDialog">
            <SvgIcon icon="close" />
          </q-btn>
          <div class="container">
            <div class="row no-wrap">
              <div class="col text-center">
                <h4 class="text-h5 text-black q-mt-none q-mb-md">
                  Write a Review
                </h4>
              </div>
            </div>
          </div>
        </div>

        <q-scroll-area
          :thumb-style="scrollAreaThumbStyle"
          :bar-style="scrollAreaBarStyle"
          :visible="scrollAreaVisible"
          style="height: calc(90vh - 130px); max-width: 100%"
        >
          <div class="container">
            <div class="row text-center">
              <div class="col-12">
                <p class="review-dialog-image">
                  <q-img
                    :src="profileImage | defaultProductImage"
                    spinner-color="primary"
                    :ratio="1"
                    style="height: 100px; width: 100px"
                    contain
                  />
                </p>
                <h5 class="review-dialog-title" v-html="productName" />
                <q-rating
                  v-model="review.ratingDialog"
                  icon="star_border"
                  icon-selected="star"
                  :max="5"
                  no-dimming
                  class="review-dialog-rating"
                  @input="
                    (val) => {
                      review.ratingDialog = val
                    }
                  "
                />
                <div
                  :style="{
                    opacity:
                      showRatingError && review.ratingDialog == 0 ? 1 : 0,
                  }"
                  class="q-mb-lg text-negative"
                >
                  <!-- <q-icon name="info" size="22px" /> -->
                  Rating is required</div
                >
              </div>
              <div class="col-12">
                <q-form
                  class="q-gutter-sm review-dialog-form"
                  @submit="submitReview"
                  ref="reviewForm"
                >
                  <div class="form-field">
                    <q-input
                      outlined
                      bottom-slots
                      v-model="review.writeTitle"
                      label="Title of the review"
                      maxlength="50"
                      :rules="[(val) => validateRequired(val)]"
                      :lazy-rules="lazyValidationRules"
                    >
                      <!-- placeholder="How would you summarize your review?" -->
                      <template v-slot:append>
                        <q-icon
                          v-if="text !== ''"
                          name="close"
                          @click="text = ''"
                          class="cursor-pointer"
                        />
                      </template>
                    </q-input>
                    <span
                      class="float-right text-caption"
                      v-text="`${review.writeTitle.length}/50`"
                    />
                  </div>
                  <div class="form-field__area">
                    <q-input
                      outlined
                      autogrow
                      bottom-slots
                      v-model="review.writeMessage"
                      type="textarea"
                      label="Details please! Your review helps other shoppers."
                      rows="3"
                      maxlength="500"
                    >
                      <!-- placeholder="Message" -->
                      <template v-slot:append>
                        <q-icon
                          v-if="text !== ''"
                          name="close"
                          @click="text = ''"
                          class="cursor-pointer"
                        />
                      </template>
                    </q-input>
                    <span
                      class="float-right text-caption"
                      v-text="`${review.writeMessage.length}/500`"
                    />
                  </div>
                  <div class="form-field" v-if="false">
                    <q-file
                      rounded
                      outlined
                      bottom-slots
                      v-model="model"
                      label="Browse Photos"
                      class="text-center"
                      width="200"
                      dense
                    >
                      <template v-slot:hint> 5MB maximum size each </template>
                    </q-file>
                  </div>
                  <div class="form-action">
                    <q-btn
                      unelevated
                      no-caps
                      rounded
                      color="secondaryOnBody"
                      label="Submit Review"
                      class="q-pa-sm q-pl-lg q-pr-lg"
                      type="submit"
                      :loading="submitting"
                    >
                      <q-tooltip
                        max-width="180px"
                        anchor="top middle"
                        self="bottom middle"
                        :offset="[10, 10]"
                        content-class="bg-dark"
                        v-if="review.ratingDialog <= 0"
                        ref="submitToolTip"
                      >
                        Please Select Rating.
                      </q-tooltip>
                    </q-btn>
                    <p
                      v-if="businessConfigurations.customerReviewPolicy"
                      class="q-pt-lg"
                    >
                      By submitting this review, you are agreeing to our
                      <a
                        v-if="businessConfigurations.customerReviewPolicy"
                        href="javascript:void(0)"
                        @click="
                          changeDialogState({
                            dialog: 'CMSDialog',
                            parentDialog: 'writeReview',
                            val: true,
                            properties: {
                              title: 'Reviews Terms & Guidelines',
                              content:
                                businessConfigurations.customerReviewPolicy,
                            },
                          })
                        "
                        class="text-dark"
                      >
                        Review Policy
                      </a>
                    </p>
                  </div>
                </q-form>
              </div>
            </div>
          </div>
        </q-scroll-area>
      </q-card>
    </template>
  </ftx-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
function initialState() {
  return {
    submitting: false,
    model: [],
    text: '',
    review: {
      writeMessage: '',
      writeTitle: '',
      ratingDialog: 0,
    },
    productName: '',
    profileImage: '',
    seoName: '',
    productID: '',
    dialog: 'writeReview',
    isChangesUnsaved: false,
    showRatingError: false,
  }
}

export default {
  name: 'WriteReview',
  data: function () {
    return initialState()
  },
  computed: {
    dialogState: {
      get() {
        return this.getDialogState(this.dialog)
      },
      set(val) {
        this.changeDialogState({
          dialog: this.dialog,
          val,
        })
      },
    },
    properties() {
      return this.getDialogProperties(this.dialog)
    },
  },
  methods: {
    beforeShow() {
      this.resetData()
      if (this.properties && this.properties.customerReview) {
        this.productName = this.properties.customerReview.productName
        this.profileImage = this.properties.customerReview.profileImage
        this.seoName = this.properties.customerReview.seoName
        this.productID = this.properties.customerReview.productID
        this.review.writeMessage =
          this.properties.customerReview.reviewDescription ||
          this.review.writeMessage
        this.review.writeTitle =
          this.properties.customerReview.reviewTitle || this.review.writeTitle
        this.review.ratingDialog =
          this.properties.customerReview.ratings || this.review.ratingDialog
      }
    },
    resetData() {
      Object.assign(this.$data, initialState())

      setTimeout(() => {
        this.isChangesUnsaved = false
      }, 200)
    },
    submitReview() {
      if (!this.review.ratingDialog) {
        this.$refs.submitToolTip.show()
        this.showRatingError = true
        return
      }
      const params = {
        title: this.review.writeTitle,
        description: this.review.writeMessage,
        productSeoName: this.seoName,
        productID: this.productID,
        rating: this.review.ratingDialog,
      }
      this.showRatingError = false
      this.submitting = true
      const editReview = this.properties.customerReview.editReview

      if (!this.properties.customerReview.reviewID) {
        this.$store
          .dispatch('customerReview/postCustomerReviews', params)
          .then((response) => {
            if (response) {
              this.$root.$emit('replacePendingReviewByProductID', response.data)
              this.showSuccess('Your Review Added Successfully.')
              this.dialogState = false
              if (this.$root) {
                this.$root.$emit('review-added', this.dialogState)
              }
            }
          })
          .finally(() => {
            this.submitting = false
          })
      } else {
        this.$store
          .dispatch('customerReview/editCustomerReview', {
            reviewID: this.properties.customerReview.reviewID,
            params: params,
          })
          .then((response) => {
            if (response) {
              if (response.data.status == 2) {
                this.$root.$emit(
                  'replacePublishedReviewWhenUpdate',
                  response.data
                )
              }

              if (editReview)
                this.$root.$emit('editedReviewResponse', response.data)

              this.showSuccess('Your Review Modified successfully.')
              this.dialogState = false
            }
          })
          .finally(() => {
            this.submitting = false
          })
      }
    },
    openConfirmationPopup() {
      const confirmationDialogTitle = 'Save Before Closing ?'
      const confirmationDialogDescription = `It looks like you're trying to close the tab before saving your changes.<br />Would you like to save or discard these changes?`
      const cancelBtnLabel = 'Discard and Close'
      const confirmBtnLabel = 'Save and Close'
      const closeBtn = false
      const outsideClickCloseDisable = true

      this.ftxConfirm(
        confirmationDialogTitle,
        confirmationDialogDescription,
        cancelBtnLabel,
        confirmBtnLabel,
        closeBtn,
        outsideClickCloseDisable
      )
        .onOk(() => {
          this.$refs.reviewForm.validate().then((success) => {
            if (success) this.submitReview()
          })
        })
        .onCancel(() => {
          this.dialogState = false
        })
    },
    closeDialog() {
      if (!this.isChangesUnsaved) this.dialogState = false
      else this.openConfirmationPopup()
    },
  },
  watch: {
    review: {
      handler(newVal) {
        if (newVal) {
          this.isChangesUnsaved = true
        }
      },
      deep: true,
    },
  },
}
</script>
<style lang="scss">
/* Write Review Dialog */
.writeReview {
  // padding: 0px 0 40px;
  padding: 0px;
  .dialogHeader {
    padding: 30px 0 30px;
    .close {
      position: absolute;
      right: 0;
      top: 0;
      width: 60px;
      height: 60px;
      font-size: 8px;
    }
    h4.text-h5 {
      margin: 0;
      font-size: 20px;
      font-family: 'Poppins', sans-serif;
      font-weight: 500;
      @media (min-width: 1681px) {
        font-size: 24px;
      }
    }
    @media (max-width: 599px) {
      padding: 20px 0;
      background: $primary;
      background: var(--q-color-primary) !important;
      color: $white;
      margin-bottom: 20px;
      h4.text-h5 {
        padding-right: 60px;
        font-size: 18px;
        color: #fff !important;
        text-align: left;
      }
      .close {
        width: 70px;
        height: 70px;
      }
    }
  }
  .container {
    max-width: 660px;
  }
  .review-dialog-image {
    img {
      padding: 5px;
      border: 1px solid #eeeeee;
    }
  }
  .review-dialog-title {
    margin: 0;
    padding: 0;
    font-size: 20px;
    color: #314154;
  }
  .review-dialog-rating {
    padding: 20px 0 15px;
    color: #707070;
    font-size: 2em;
    .q-rating__icon {
      text-shadow: none;
    }
    .q-rating__icon--active {
      color: #ffbd09;
    }
    @media (min-width: 1681px) {
      font-size: 2.5em;
    }
  }
  .review-dialog-form {
    .q-file {
      max-width: 230px;
      .q-field__label,
      .q-field__messages {
        text-align: center;
      }
      .q-field__messages {
        font-size: 14px;
      }
      &.q-field--float {
        .q-field__label {
          transform: none;
          right: 0;
        }
      }
    }
  }
  @media (max-width: 599px) {
    padding: 0px 0 20px;
  }
}
/* Write Review Dialog */
</style>
